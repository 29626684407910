/*
 * @Description: 商品管理api
 * @Autor: WangYuan
 * @Date: 2021-09-22 15:51:56
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-09-22 16:55:04
 */
import request from '@/utils/request'
import global from '../config/global'

/**
 * 查询商品列表
 */
export const getGoodsList = async (data) => request({ url: `${global.apiPrefix}/diylayout-diylayout-goodsList `, method: "POST", data })

/**
 * 根据id查询商品详情
 */
export const getGoodsById = async (data) => request({ url: "goods/getById", method: "POST", data })

/**
 * 根据id列表查询商品列表
 */
 export const getGoodsByIds = async (data) => request({ url: "goods/getByIds", method: "POST", data })

