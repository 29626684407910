/*
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2022-02-11 10:08:57
 * @LastEditors: WangYuan
 * @LastEditTime: 2022-11-02 09:10:53
 */
export default {
  apiPrefix: 'addons/execute',
  // baseApi: 'http://localhost:3000/',  // 后端接口地址
  baseApi: '/', // 后端接口地址
  // baseApi: 'http://110.41.150.71:3000/',  // 后端接口地址
  // viewUrl: 'https://diy.wo-shop.net/h5/#/', // iframe嵌套的H5地址,本地开发可改为本地启动的H5地址
  //viewUrl: process.env.NODE_ENV === 'production' ? 'https://shop.9hcmall.com/h5/#/' : 'http://localhost:8080/h5/#/',
  viewUrl: process.env.NODE_ENV === 'production' ? 'https://h53.9hcmall.com/#/' : 'http://localhost:8080/h5/#/',
  seceretKey: 'yiling6670238160ravntyoneapp7926'
  // viewUrl: 'http://110.41.150.71:3000/h5/#/' // iframe嵌套的H5地址,本地开发可改为本地启动的H5地址
}
