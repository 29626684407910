/*
 * @Description: 项目配置数据
 * @Autor: WangYuan
 * @Date: 2021-08-31 19:57:35
 * @LastEditors: WangYuan
 * @LastEditTime: 2022-11-02 09:55:11
 */

// 固定页面集合
export const fixedPages = [
  {
    id: 'search',
    name: '搜索页',
    type: 'fixed',
    path: ''
  },
  {
    id: 'category',
    name: '商品分类',
    type: 'fixed',
    path:'pagesC/goods/categoryGoods'
  },
    {
    id: 'liveList',
    name: '直播',
    type: 'fixed',
    path:'/pagesC/live/index'
  },
  {
    id: 'turntable',
    name: '抽奖',
    type: 'fixed',
    path:'/pagesC/raffle/turntable'
  },
  {
    id: 'secKill',
    name: '超级秒杀',
    type: 'fixed',
    path:'/pagesC/goods/secKill'
  },
    {
    id: 'assembleList',
    name: '人人拼团',
    type: 'fixed',
    path:'/pagesC/goods/assembleList'
  },
      {
    id: 'shopList',
    name: '店铺街',
    type: 'fixed',
    path:'/pagesC/shop/shopList'
  },
        {
    id: 'getCoupon',
    name: '优惠券',
    type: 'fixed',
    path:'/pagesB/coupon/getCoupon'
  },
          {
    id: 'signin',
    name: '每日签到',
    type: 'fixed',
    path:'/pagesB/my/signin'
  },
   {
    id: 'tagGoodsList',
    name: '新品首发',
    type: 'fixed',
    path:'/pagesC/goods/tagGoodsList?tag=new'
  },
  {
    id: 'integral',
    name: '积分商城',
    type: 'fixed',
    path:'/pagesC/integral/index'
  },
  {
    id: 'tab',
    name: '全部分类',
    type: 'fixed',
    path:'/pages/tabBar/category'
  },
]

// 导航配置
export const navigation = {
  label: '导航',
  styles: {
    background: '#fff'
  },
  list: [
    {
      id: '00001',
      icon: 'icon-shop',
      text: '首页',
      jump: {
        type: 'custom',
        id: '000000'
      }
    },
    {
      id: '00003',
      icon: 'icon-sort',
      text: '分类',
      jump: {
        type: 'fixed',
        id: 'category'
      }
    },
    {
      id: '00004',
      icon: 'icon-cart',
      text: '购物车',
      jump: {
        type: 'fixed',
        id: 'car'
      }
    },
    {
      id: '00005',
      icon: 'icon-my',
      text: '我的',
      jump: {
        type: 'fixed',
        id: 'my'
      }
    }
  ]
}

// 商品分组配置
export const goodsGroups = [
  {
    name: '新品上架',
    type: '0001',
    level: 1,
    child: []
  },
  {
    name: '热销排行',
    type: '0002',
    level: 1,
    child: []
  }
]

// 商品列表模板配置
export const listTpl = {
  model: 'one',
  addIcon: 'cart'
}

// 商品列表模板配置
export const categoryTpl = {
  advertising: false // 广告位
}

// 商城初始配置
export const rojectModel = {
  name: '',
  cover:
    'https://i.niupic.com/images/2022/11/02/aaa2.jpeg',
  config: {
    navigation: navigation,
    goodsGroups: goodsGroups,
    listTpl: listTpl,
    categoryTpl: categoryTpl,
    hotList: [],
    isOpen: '0',
    backgroundColor: '#FFFFFF'
  },
  pages: [
    // 自定义页面集合
    {
      id: '000000',
      name: '首页',
      home: true,
      componentList: []
    }

  ]
}
